import { Screen } from "../../../../../shared/ui/screen/Screen";
import { type ActivityLayoutComponentType } from "../../../../../stackflow/routes/types";
import { type Params } from "./ActivityLocalProfileSuggestionDelete";

const ActivityLocalProfileSuggestionDeleteLayout: ActivityLayoutComponentType<
  Params
> = ({ children }) => {
  return (
    <Screen
      appBar={{
        title: "장소 삭제",
        border: false,
      }}
    >
      {children}
    </Screen>
  );
};

export default ActivityLocalProfileSuggestionDeleteLayout;
