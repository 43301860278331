import { useActivity } from "@stackflow/react";
import { type MouseEvent, useCallback, useLayoutEffect, useState } from "react";

import { Screen } from "../../../../shared/ui/screen/Screen";
import type { ActivityLayoutComponentType } from "../../../../stackflow/routes/types";
import { useStepFlow } from "../../../../stackflow/Stack";
import { useNavigator } from "../../../../stackflow/useNavigator";
import { type Params } from "./ActivityLocalProfileSuggestionCreate";

const ActivityLocalProfileSuggestionCreateLayout: ActivityLayoutComponentType<
  Params
> = ({ children }) => {
  const { steps } = useActivity();
  const { stepPop } = useStepFlow("local_profile.suggestion.create");
  const { pop } = useNavigator();
  const [preventSwipeBack, setPreventSwipeBack] = useState(false);

  const handleBack = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();

      if (steps.length === 1) {
        pop();

        return;
      }

      stepPop();
    },
    [pop, stepPop, steps.length],
  );

  useLayoutEffect(() => {
    const preventBackSwipeForStep = () => {
      setPreventSwipeBack(true);

      return function dispose() {
        setPreventSwipeBack(false);
      };
    };

    const dispose = preventBackSwipeForStep();

    return () => {
      dispose();
    };
  }, []);

  return (
    <Screen
      // 생성 제안 퍼널이 step으로 구분되어 있어서 스와이프 백을 할경우 이전 화면이 아닌 이전 액티비티로 이동해요.
      // 이를 방지하기 위해 스텝으로 구성된 페이지에서는 스와이프 백을 막아둬요.
      preventSwipeBack={preventSwipeBack}
      appBar={{
        closeButton: {
          onClick: handleBack,
        },
        backButton: {
          onClick: handleBack,
        },
      }}
    >
      {children}
    </Screen>
  );
};

export default ActivityLocalProfileSuggestionCreateLayout;
