import { Screen } from "../../../../../../shared/ui/screen/Screen";
import { type ActivityLayoutComponentType } from "../../../../../../stackflow/routes/types";

type PathParams = {
  /**
   * 로컬프로필 ID
   */
  local_profile_id: string;
};
type QueryParams = {};
export type ActivityLocalProfileUpdateNamePararms = PathParams & QueryParams;
const ActivityLocalProfileUpdateNameLayout: ActivityLayoutComponentType<
  ActivityLocalProfileUpdateNamePararms
> = ({ children }) => {
  return (
    <Screen appBar={{ title: "장소 이름", border: false }}>{children}</Screen>
  );
};

export default ActivityLocalProfileUpdateNameLayout;
