import { Spinner } from "@daangn/sprout-components-spinner";
import { Suspense, useMemo } from "react";

import { useReferrerAndEntry } from "../../../../../entities/referrer/lib";
import { LOCAL_BUSINESS_UGC_SERVICE_NAME } from "../../../../../shared/constants/environment";
import { BottomFixedButton } from "../../../../../shared/ui/layout/BottomFixedButton";
import { Flex } from "../../../../../shared/ui/layout/Flex";
import { Screen } from "../../../../../shared/ui/screen/Screen";
import { type ActivityLayoutComponentType } from "../../../../../stackflow/routes/types";
import { useNavigator } from "../../../../../stackflow/useNavigator";
import * as css from "./ActivityLocalProfileUpdate.css";

const LOCAL_PROFILE_CONTRIBUTE_RECOMMENDATIONS_PAGE = `${LOCAL_BUSINESS_UGC_SERVICE_NAME}.local_profile.contribute.recommendations`;

type PathParams = {
  /**
   * 로컬프로필 ID
   */
  local_profile_id: string;
};
type QueryParams = {
  /**
   * 수정 제안 항목 (comma separated string)
   * - 순서대로 렌더링
   * - 미지정 시 모든 항목 렌더링
   */
  fields?: string;

  /**
   * 진입한 퍼널 이름
   * - 수정 제안으로 오는 퍼널을 구분하기 위해 사용.
   * - referrer 보다 명시적으로 사용하기 위해 사용.
   * */
  funnel_name?: "local_profile_suggestion_create";
};

export type ActivityLocalProfileUpdateParams = PathParams & QueryParams;

const ActivityLocalProfileUpdateLayout: ActivityLayoutComponentType<
  ActivityLocalProfileUpdateParams
> = ({ children, params }) => {
  const { pop } = useNavigator();
  const { incomingEntry } = useReferrerAndEntry();

  const title = useMemo(() => {
    if (params.funnel_name === "local_profile_suggestion_create")
      return `이곳의 업체 정보를 추가해 주세요\n당근에서 확인 후에 반영할게요`;

    return `이곳의 업체 정보를 알려주세요\n새로 추가하거나 수정할 수 있어요`;
  }, [params.funnel_name]);

  return (
    <Screen>
      <BottomFixedButton
        actions={[
          {
            elementType: "button",
            type: "button",
            size: "xlarge",
            "aria-label": "완료",
            children: "완료",
            onClick: () => {
              // 진입점이 기여모드 장소추천 화면인 경우 2페이지 이전으로 이동합니다.
              // flow: 기여모드 메인 -push-> 기여모드 장소추천 -push-> 로컬프로필 수정 -pop(2)-> 기여모드 메인)
              if (
                incomingEntry === LOCAL_PROFILE_CONTRIBUTE_RECOMMENDATIONS_PAGE
              ) {
                pop(2);

                return;
              }
              pop();
            },
          },
        ]}
      >
        <Flex height="full" direction="column">
          <div className={css.headingWrapper}>
            <h2 className={css.heading}>{title}</h2>
          </div>

          <Suspense fallback={<Spinner />}>{children}</Suspense>
        </Flex>
      </BottomFixedButton>
    </Screen>
  );
};

export default ActivityLocalProfileUpdateLayout;
