import { type RemoteConfig } from "./types";

export const REMOTE_CONFIG_FALLBACK: RemoteConfig = {
  social_capital_maintenance_mode: false,
  BIZPROFILE_NAME_MAX_LENGTH: 20, // 비즈프로필의 이름 등록시 최대로 입력할 수 있는 글자수
  BIZPROFILE_NAME_MIN_LENGTH: 2, // 비즈프로필의 이름 등록시 최소로 입력해야할 글자수
  POI_DELETE_SUGGESTION_IMAGE_MAX_COUNT: 10, // POI 삭제 제안 이미지 최대 개수
  LOCAL_PROFILE_SUGGESTION_UPDATE_BACKGROUND_IMAGE_MAX_COUNT: 10, // 로컬 프로필 배경 이미지 제안 최대 개수
  LOCAL_PROFILE_SUGGESTION_UPDATE_PHONE_IMAGE_MAX_COUNT: 3, // 업체 전화번호 제안 이미지 최대 개수
  LOCAL_PROFILE_SUGGESTION_UPDATE_OPERATION_TIME_IMAGE_MAX_COUNT: 10, // 영업시간 제안 이미지 최대 개수
};
