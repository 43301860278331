import { Screen } from "../../../../../../shared/ui/screen/Screen";
import { type ActivityLayoutComponentType } from "../../../../../../stackflow/routes/types";

type PathParams = {
  /**
   * 로컬프로필 ID
   */
  local_profile_id: string;
};
type QueryParams = {};
export type ActivityLocalProfileUpdateWebsitePararms = PathParams & QueryParams;
const ActivityLocalProfileUpdateWebsiteLayout: ActivityLayoutComponentType<
  ActivityLocalProfileUpdateWebsitePararms
> = ({ children }) => {
  return (
    <Screen appBar={{ title: "웹사이트", border: false }}>{children}</Screen>
  );
};

export default ActivityLocalProfileUpdateWebsiteLayout;
