/**
 * Generates a secure random floating-point number between 0 (inclusive) and 1 (exclusive).
 *
 * This function uses the Web Crypto API's `window.crypto.getRandomValues` method to generate
 * a cryptographically secure random number. If the Web Crypto API is not available, it falls
 * back to using `Math.random()`, which is not secure for cryptographic purposes.
 *
 * @returns {number} A random floating-point number between 0 (inclusive) and 1 (exclusive).
 */
export const getSecureRandomFloat = (): number => {
  if (window.crypto && typeof window.crypto.getRandomValues === "function") {
    const randomValue = new Uint32Array(1);
    window.crypto.getRandomValues(randomValue);
    return randomValue[0] / (0xffffffff + 1);
  }

  console.warn(
    "window.crypto.getRandomValues is not supported in this environment. Falling back to Math.random(). This is not secure for cryptographic purposes.",
  );
  return Math.random();
};
